import clsx from 'clsx';
import type { ReactElement, ReactNode } from 'react';

import ScrollShow from '../ScrollShow';

import Section from './Section';

export interface FeatureListProps {
  children: ReactNode;
}

export default function FeatureList(props: FeatureListProps) {
  return (
    <div className="bg-white">
      <ScrollShow className="text-white" />
      <div className="mx-auto flex max-w-7xl flex-col items-center gap-y-16 pb-3">
        <Section title="Features" isWhite className="pt-20" id="features" />
        <div className="mx-10 -mt-12 sm:-mt-8 lg:col-span-2">
          <dl className="flex flex-wrap items-center justify-center gap-10">
            {props.children}
          </dl>
        </div>
      </div>
    </div>
  );
}

export interface FeatureListItemProps {
  title: string;
  icon: ReactElement;
  isSmall?: boolean;
  children: ReactNode;
}

FeatureList.Item = function FeatureListItem(props: FeatureListItemProps) {
  const { title, children } = props;

  return (
    <div
      className={clsx(
        'mx-2 max-w-xs rounded-3xl bg-neutral-100 py-5 shadow-md sm:mx-0',
        {
          'h-44 sm:h-48': props.isSmall,
          'h-64 sm:h-60': !props.isSmall,
        },
      )}
    >
      <dt className="flex justify-center space-x-4 px-2">
        <div className="text-primary-600">{props.icon}</div>
        <p className="text-base font-medium leading-6 text-neutral-900 sm:text-lg">
          {title}
        </p>
      </dt>
      <dd className="-mt-3 flex h-full items-center px-8 text-sm text-neutral-500 sm:text-base">
        <p>{children}</p>
      </dd>
    </div>
  );
};
