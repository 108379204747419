import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useState } from 'react';

export default function ScrollShow(props: { className?: string }) {
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled < 200) {
      setVisible(true);
    } else if (scrolled >= 200) {
      setVisible(false);
    }
  };

  const scrollDown = () => {
    window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', toggleVisible);
  }

  return (
    <div className={props.className} onClick={scrollDown}>
      <div className="flex justify-center">
        <div className="z-20 -mt-28 h-16 w-16">
          <ChevronDownIcon
            className={clsx('animate-bounce cursor-pointer opacity-80', {
              'mb-4 mt-8 hidden lg:inline': visible,
              hidden: !visible,
            })}
          />
        </div>
      </div>
    </div>
  );
}
