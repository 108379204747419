import Section from './Section';

interface VideoProps {
  link: string;
  title?: string;
}

export default function Video(props: VideoProps) {
  const { link, title } = props;

  return (
    <div className="flex w-full flex-col items-center gap-10">
      {title ? <Section title={title} isWhite className="-mb-4" /> : null}
      <iframe
        title={title}
        src={link}
        className="aspect-video w-full"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}
