import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';

import ParticlesTs from '../ParticlesTs';

export interface CtaLinkProps {
  to: string;
  label: string;
  className?: string;
}

export interface CtaProps {
  name: ReactNode;
  children: ReactNode;
  linksChildren: ReactNode;
}

interface TestimonialType {
  id: number;
  quote: string;
  name?: string;
  title?: string;
  pic?: string;
  linkedin?: string;
}

interface TestimonialCaptionProps {
  testimonial: TestimonialType;
}

export default function CustomerTestimonial(props: CtaProps) {
  return (
    <div className="flex flex-col justify-center pb-4 pt-16 lg:pt-0">
      <div className="lg:grid lg:h-screen lg:grid-cols-2 pt-8">
        <div className="z-10 flex flex-col items-center justify-center">
          <div className="max-w-xl px-11 lg:pt-8 sm:px-6 lg:col-span-1 lg:px-12 xl:px-16">
            <h2 className="text-4xl font-extrabold text-white sm:text-5xl">
              {props.name}
            </h2>
            <p className="justify mt-8 text-base leading-6 text-primary-200 sm:text-lg">
              {props.children}
            </p>
            {props.linksChildren}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <ParticlesTs
            isSmall
            className="absolute hidden lg:-ml-16 lg:block lg:h-full xl:-ml-24"
          />
          <Testimonials />
        </div>
      </div>
    </div>
  );
}

CustomerTestimonial.Description = function CtaDescription(props: {
  children: ReactNode;
}) {
  return <span className="block">{props.children}</span>;
};

function Testimonials() {
  const testimonialList = useMemo(() => {
    return [
      {
        quote:
          'Our research group has been using NMRium exclusively for the past one and half years due to its easy-to-use user interface, comprehensive capabilities and open file formats.',
        name: 'Jacob Schneidewind',
        title:
          'Independent research group leader, Friedrich Schiller University Jena',
        pic: '/testimonials/jschneidewind.jpg',
        linkedin: 'https://www.linkedin.com/in/jacob-schneidewind-5153a6b0/',
      },
      {
        quote:
          'NMRium stands as the most efficient tool for data inspection across every stage of our analytical pipeline.',
        name: 'Professor Julien Wist',
        title: 'Health Futures Institute, Murdoch University',
        pic: '/testimonials/jwist.jpg',
        linkedin: 'https://www.linkedin.com/in/nmrlab/',
      },
      {
        name: 'Nils Schlörer',
        quote:
          'NMRium comes out of the box as a fully equipped browser application and is outstandingly suitable for teaching and research',
        title: 'Head of the NMR Platform, Friedrich Schiller University Jena',
        pic: '/testimonials/nschlorer.jpg',
      },
      {
        quote:
          'I really like NMRium for processing 1D and 2D NMR. It is so much more convenient than any other NMR processing software that I have used.',
        name: '',
        title: 'Senior Chemist, Baruch S. Blumberg Institute',
        pic: '',
        linkedin: '',
      },
      {
        quote:
          'Switching to NMRium has simplified and enhanced our data processing and analysis immensely and I cannot recommend it enough.',
        name: 'Jacob Schneidewind',
        title:
          'Independent research group leader, Friedrich Schiller University Jena',
        pic: '/testimonials/jschneidewind.jpg',
        linkedin: 'https://www.linkedin.com/in/jacob-schneidewind-5153a6b0/',
      },
      {
        quote:
          'New students are usually able to learn to use NMRium within their first day and the browser-based nature makes NMR analysis possible from any machine at any time.',
        name: 'Jacob Schneidewind',
        title:
          'Independent research group leader, Friedrich Schiller University Jena',
        pic: '/testimonials/jschneidewind.jpg',
        linkedin: 'https://www.linkedin.com/in/jacob-schneidewind-5153a6b0/',
      },
    ];
  }, []);

  const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const shuffledTestimonials = testimonialList
      .sort(() => Math.random() - 0.5)
      .map((testimonial, index) => ({
        ...testimonial,
        id: index,
      }));
    setTestimonials(shuffledTestimonials);
  }, [testimonialList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial(
        (prevTestimonial) => (prevTestimonial + 1) % testimonials.length,
      );
    }, 10000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="flex items-center justify-center">
      {testimonials.map((testimonial) => (
        <figure
          key={testimonial.id}
          className={clsx(
            'mx-11 my-10 flex max-w-2xl flex-col items-center justify-center rounded-[50px] bg-primary-50 bg-opacity-20 p-6 text-white shadow-lg backdrop-blur-sm lg:mr-20',
            {
              'absolute z-0 opacity-0 transition-opacity duration-1000':
                testimonial.id !== currentTestimonial,
              'z-10 opacity-100 transition-opacity duration-[2000ms]':
                testimonial.id === currentTestimonial,
            },
          )}
        >
          <QuoteSymbol />
          <blockquote className="text-center leading-relaxed sm:text-2xl sm:font-medium">
            &quot;{testimonial.quote}&quot;
          </blockquote>
          <TestimonialCaption testimonial={testimonial} />
        </figure>
      ))}
    </div>
  );
}

function TestimonialCaption({ testimonial }: TestimonialCaptionProps) {
  return (
    <figcaption className="mt-6 flex items-center justify-center space-x-3">
      <div className="flex flex-col items-center divide-y-2 px-4 text-center sm:flex-row sm:divide-x-2 sm:divide-y-0">
        {testimonial.name ? (
          <a
            href={testimonial.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(
              'flex h-full items-center justify-center gap-4 pb-4 font-medium sm:pb-0',
              { 'sm:pr-8': testimonial.title },
            )}
          >
            {testimonial.pic ? (
              <img
                className="h-9 w-9 rounded-full"
                src={testimonial.pic}
                alt="profile picture"
              />
            ) : null}
            {testimonial.name}
          </a>
        ) : null}
        {testimonial.title ? (
          <div
            className={clsx('pt-4 text-sm font-light sm:pt-0 sm:font-normal', {
              'sm:pl-4': testimonial.name,
            })}
          >
            {testimonial.title}
          </div>
        ) : null}
      </div>
    </figcaption>
  );
}

function QuoteSymbol() {
  return (
    <svg
      className="text-gray-400 dark:text-gray-600 mx-auto mb-3 h-12"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
        fill="currentColor"
      />
    </svg>
  );
}
