import clsx from 'clsx';
import NextLink from 'next/link';
import type { ReactNode } from 'react';

interface LinkProps {
  children: ReactNode;
  href: string;

  className?: string;
  target?: string;
  rel?: string;
}

export function Link(props: LinkProps) {
  return (
    <span
      className={clsx(
        props.className,
        'cursor-pointer text-center underline hover:text-primary-500 md:w-3/6 md:text-left',
      )}
    >
      <NextLink
        href={props.href}
        prefetch={false}
        target={props.target}
        rel={props.rel}
      >
        {props.children}
      </NextLink>
    </span>
  );
}
