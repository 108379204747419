import clsx from 'clsx';

export interface Props {
  title: string;
  isWhite?: boolean;
  id?: string;
  className?: string;
}

export default function Section(props: Props) {
  return (
    <div className="group flex w-full justify-center" id={props.id}>
      <div className={props.className}>
        <h5
          className={clsx(
            'mb-8 flex gap-x-3 text-center text-4xl leading-10 tracking-tight sm:text-5xl sm:leading-none',
            {
              'font-bold text-primary-600 ': props.isWhite,
              'font-semibold text-white': !props.isWhite,
            },
          )}
        >
          {props.title}
          {props.id ? (
            <a
              href={`#${props.id}`}
              className="mt-1 text-3xl opacity-0 duration-150 ease-in-out hover:underline group-hover:opacity-100 sm:mt-2 sm:text-4xl"
            >
              #
            </a>
          ) : null}
        </h5>
      </div>
    </div>
  );
}
